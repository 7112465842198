// Videos

window.SiteReady(($) => {
  console.log('Init: Video');

  // Not Used

  // if ($('.js-video_modal, .js-video_swap').length > 0 && $('script[src*="youtube.com/iframe_api"]').length == 0) {
  //   $('head').append('<script src="//www.youtube.com/iframe_api"></script>');
  // }

  // let originalYouTubeCallback = window.onYouTubeIframeAPIReady || null; // Fixes background race condition

  // window.onYouTubeIframeAPIReady = () => {
  //   if (typeof originalYouTubeCallback == 'function') {
  //     originalYouTubeCallback();
  //   }
  // }

  function playVideo($iframe) {
    // $('iframe.playing').not('.autoplay').each(function(el) {
    //   pauseVideo($(el));
    // });

    // $iframe.addClass('playing');

    console.log('play');

    if ($iframe.data('provider') == 'SproutVideo') {
      $iframe[0].contentWindow.postMessage(JSON.stringify({name:"play"}), '*');
    }
    if ($iframe.data('provider') == 'YouTube') {
      $iframe[0].contentWindow.postMessage(JSON.stringify({event:"command",func:"playVideo",args:""}), '*');
    }
    if ($iframe.data('provider') == 'Vimeo') {
      $iframe[0].contentWindow.postMessage(JSON.stringify({method:"play"}), '*');
    }
  }

  function pauseVideo($iframe) {
    // $iframe.removeClass('playing');

    if ($iframe.data('provider') == 'SproutVideo') {
      $iframe[0].contentWindow.postMessage(JSON.stringify({name:"pause"}), '*');
    }
    if ($iframe.data('provider') == 'YouTube') {
      $iframe[0].contentWindow.postMessage(JSON.stringify({event:"command",func:"pauseVideo",args:""}), '*');
    }
    if ($iframe.data('provider') == 'Vimeo') {
      $iframe[0].contentWindow.postMessage(JSON.stringify({method:"pause"}), '*');
    }
  }

  function muteVideo($iframe) {
    if ($iframe.data('provider') == 'SproutVideo') {
      $iframe[0].contentWindow.postMessage(JSON.stringify({name:"volume",data:0}), '*');
    }
    if ($iframe.data('provider') == 'YouTube') {
      $iframe[0].contentWindow.postMessage(JSON.stringify({event:"command",func:"mute",args:""}), '*');
      $iframe[0].contentWindow.postMessage(JSON.stringify({event:"command",func:"setVolume",args:[0]}), '*');
    }
    if ($iframe.data('provider') == 'Vimeo') {
      $iframe[0].contentWindow.postMessage(JSON.stringify({method:"mute"}), '*');
      $iframe[0].contentWindow.postMessage(JSON.stringify({method:"setVolume",value:0}), '*');
    }

    $iframe.next('.js-video_audio_control').addClass('muted');
  }

  function unmuteVideo($iframe) {
    if ($iframe.data('provider') == 'SproutVideo') {
      $iframe[0].contentWindow.postMessage(JSON.stringify({name:"volume",data:1}), '*');
    }
    if ($iframe.data('provider') == 'YouTube') {
      $iframe[0].contentWindow.postMessage(JSON.stringify({event:"command",func:"unMute",args:""}), '*');
      $iframe[0].contentWindow.postMessage(JSON.stringify({event:"command",func:"setVolume",args:[100]}), '*');
    }
    if ($iframe.data('provider') == 'Vimeo') {
      $iframe[0].contentWindow.postMessage(JSON.stringify({method:"unmute"}), '*');
      $iframe[0].contentWindow.postMessage(JSON.stringify({method:"setVolume",value:1}), '*');
    }

    $iframe.next('.js-video_audio_control').removeClass('muted');
  }

  //

  // $('.js-video_swap, .js-video_modal').on('swap:activate', (e) => {
  $('.js-video_swap').on('swap:activate', (e) => {
    let $target = $(e.currentTarget);
    let $iframe = $target.find('.js-video_player');

    playVideo($iframe);

    $iframe.data('state', 'play');

    // let windowWidth = window.innerWidth;

    // if (windowWidth < 740) {
    //   document.documentElement.requestFullscreen();
    // }
  }).on('swap:deactivate', (e) => {
    let $target = $(e.currentTarget);
    let $iframe = $target.find('.js-video_player');

    pauseVideo($iframe);

    $iframe.data('state', 'pause');

    // if (document.fullscreenElement != null) {
    //   document.exitFullscreen();
    // }
  });

  // $('.js-video_modal').on('click', (e) => {
  //   let $target = $(e.currentTarget);

  //   if ($target.hasClass('js-video_modal')) {
  //     $target.find('.js-video_modal_close').swap('deactivate');
  //   }
  // });

  //

  function initVideoPlayer($target) {
    let player = $target.data('videoPlayer');

    if (typeof player == 'undefined') {
      let $iframe = $target.find('.js-video_player');
      let src = $iframe.attr('src');

      if (typeof src == 'undefined') {
        $iframe.attr('src', $iframe.data('src'));
      }

      $target.data('videoPlayer', $iframe);

      $iframe.on('load', (e) => {
        observer.observe($iframe[0]);
      });
    }
  }

  // window.addEventListener('message', function() {
  //   console.log(arguments[0].origin, arguments[0].data);
  // });

  // $('.js-video_swap, .js-video_modal').each((i, el) => {
  $('.js-video_swap').each((i, el) => {
    initVideoPlayer($(el));
  });

  //

  function observeVideos(entries) {
    entries.forEach(function(entry) {
      let $iframe = $(entry.target);
      let state = $iframe.data('state');

      if (state == 'play') {
        if (entry.isIntersecting && $iframe.hasClass('autoplay')) {
          playVideo($iframe);
        } else {
          pauseVideo($iframe);
        }
      }
    });
  }

  let observer = new IntersectionObserver(observeVideos, {
    root: null,
    rootMargin: '0px 0px 0px 0px',
    threshold: 0.1
  });

  // $('.js-video_swap iframe').each((i, el) => {
  //   observer.observe(el);
  // });

  $('.js-video_audio_control').on('click', (e) => {
    let $target = $(e.currentTarget);
    let $iframe = $target.prev('iframe');
    let audio = $iframe.data('audio');

    if (!audio) {
      audio = 'off';
    }

    if (audio == 'off') {
      unmuteVideo($iframe);

      $iframe.data('audio', 'on');
    } else {
      muteVideo($iframe);

      $iframe.data('audio', 'off');
    }
  });

});
